<template>
  <v-card elevation="0">
    <HeaderView title="Pop Up" @openToAdd="openDialog" :noSearch="true"></HeaderView>
    <ListPopUps :popUps="popUps" @edit="openEdit" @remove="openRemove" />
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormPopUp
          v-if="dialog"
          :loading="loading"
          :payloadEdited="popUpEdited"
          @beforeUpdate="updatePopUp"
          @beforeCreate="createPopUp"
        ></FormPopUp>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import FormPopUp from '@/components/forms/FormPopUp.vue'
import ListPopUps from './ListPopUps.vue'
import HeaderView from '@/components/HeaderView.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import usePopUps from '@/composables/usePopUps'

export default {
  components: {
    FormPopUp,
    HeaderView,
    ListPopUps,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      popUpEdited,
      popUps,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      createPopUp,
      updatePopUp,
      openRemove,
    } = usePopUps()

    return {
      dialog,
      popUpEdited,
      popUps,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      openDialog,
      openEdit,
      closeDialog,
      // crud
      createPopUp,
      updatePopUp,
      openRemove,
    }
  },
}
</script>

<style lang="scss" scoped></style>
