<template>
  <v-card class="card-reel">
    <v-img contain max-height="250" :src="srcImage" class="grey darken-4 img-reel">
      <div class="actions-card">
        <v-spacer></v-spacer>
        <!-- <v-chip dark :color="item.visualize ? 'success' : 'secondary'" class="ma-2" small>
          {{ item.visualize ? "Activo" : "Inactivo" }}
        </v-chip> -->
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn elevation="1" v-bind="attrs" v-on="on" icon>
              <v-icon size="20">{{ icons.mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('edit', item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$emit('remove', item)">
              <v-list-item-title>Eliminar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-img>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js'
export default {
  props: {
    item: Object,
    srcImage: String,
  },
  setup(props) {
    // // console.log(props.item)
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-reel {
  .actions-card {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
