import { ref, computed } from "@vue/composition-api";
import store from "@/store/index"

const usePopUps = () => {

    // base data
    const dialog = ref(false);
    const popUpEdited = ref(null);
    const popUps = ref([]);
    const search = ref("");
    const loading = ref(false);

    // computed properties
    const titleDialog = computed(() =>
        popUpEdited.value ? "Editar Pop Up" : "Agregar Pop Up"
    );
    const subtitleDialog = computed(() =>
        popUpEdited.value ?
        "Rellene los campos correctamente para modificar los datos del Pop Up" :
        "Rellene los campos correctamente para validar los datos del nuevo Pop Up"
    );

    const closeDialog = () => {
        dialog.value = false;
        popUpEdited.value = null;
    }

    // methods
    const getPopUpsList = async() => {
        const { data, status } = await store.dispatch("fetchPopUps")
        if (status != 200) return;
        popUps.value = data;
    }

    const createPopUp = async(popUp) => {
        loading.value = true;
        const { data, status } = await store.dispatch("createPopUp", popUp)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getPopUpsList();
        closeDialog();
    }

    const updatePopUp = async(popUp) => {
        loading.value = true;
        const { data, status } = await store.dispatch("updatePopUp", popUp)
        loading.value = false;
        if (status != 200 && status != 201) return;
        await getPopUpsList();
        closeDialog();
    }

    const removePopUp = async(popUp) => {
        const val = await store.dispatch("generateConfirmDialog", {});
        if (!val) return;
        const { data, status } = await store.dispatch("removePopUp", popUp)
        if (status != 200 && status != 201 && status != 204) return;
        await getPopUpsList();
    }

    getPopUpsList();

    return {
        dialog,
        popUpEdited,
        popUps,
        search,
        loading,
        // computed
        titleDialog,
        subtitleDialog,
        // methods
        changeSearch(ev) {
            search.value = ev
        },
        openDialog() {
            dialog.value = true;
        },
        openEdit(popUp) {
            dialog.value = true;
            popUpEdited.value = {...popUp };
        },
        openRemove: removePopUp,
        closeDialog,
        createPopUp,
        updatePopUp
    };
}

export default usePopUps;
