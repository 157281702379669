<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formPopUp" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="3">
            <label for="file-input-reel">Imagen</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-file-input
              id="file-input-reel"
              v-model="image"
              outlined
              prepend-icon=""
              dense
              placeholder="Selecciona una imagen"
              :autocomplete="autocomplete"
              :rules="isEdited ? [] : required"
              chips
            ></v-file-input>
          </v-col>

          <v-col cols="12" md="3">
            <label for="link">URL de redirección</label>
          </v-col>
          <v-col cols="12" md="9" class="pb-0">
            <v-text-field
              id="link"
              v-model="payload['link']"
              outlined
              dense
              :autocomplete="autocomplete"
              :rules="isURL"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
export default {
  components: {
    FormTemplate,
  },
  props: {
    payloadEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      isURL: [
        v =>
          !v ||
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v,
          ) ||
          'El URL debe tener https:// o http://',
      ],
      autocomplete: 'off',
      isEdited: this.payloadEdited ? true : false,
      image: null,
      payload: this.payloadEdited ? { ...this.payloadEdited } : {
        link: ''
      },
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formPopUp.validate()
    },
    reset() {
      this.image = null
      this.$refs.formPopUp.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      const DTO = {
        ...this.payload,
        file_popups: this.image,
      }
      if (this.isEdited) {
        this.$emit('beforeUpdate', DTO)
      } else {
        this.$emit('beforeCreate', DTO)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
